import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";
import { Base64 } from 'js-base64';

@withI18next(["common"])
@page
class RedirectEPS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {
    this.redirectEPS(this.props);
  }

  componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      this.redirectEPS(props);
    }
  }

  @autobind
  redirectEPS(props) {
    let { search } = props;
    let params = { ...search };
    let {
      url = "",
    } = params;
    if (url !== "") {
        window.location.href = url
    } else {
        navigate("/");
    }
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default RedirectEPS;
